@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply underline;
  }
}

body {
  @apply bg-black text-gray-300;
}

#__next {
  @apply overflow-hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-white;
}

h1,
h2 {
  @apply font-serif;
}

h1 {
  @apply text-3xl md:text-5xl font-black;
}

h2 {
  @apply text-2xl md:text-4xl font-black;
}

h3,
h4,
h5,
h6 {
  @apply font-sans font-bold;
}

h3 {
  @apply text-2xl md:text-3xl;
}

h4 {
  @apply text-xl md:text-2xl;
}

h5 {
  @apply text-xl;
}

h6 {
  @apply text-lg;
}

.small-caps {
  @apply text-sm uppercase tracking-widest font-normal;
}

#homepage-main {
  background: url('/images/masthead-space-bg-desktop.jpg') no-repeat center top;
}


.primaryButton {
  @apply
    select-none
    bg-red-500
    text-white
    py-2
    px-4
    rounded-md
    hover:bg-red-300
    active:bg-red-900
    transition-all
}

.primaryButtonAlt {
  @apply
    select-none
    bg-red-500
    bg-opacity-30
    text-red-500
    py-2
    px-4
    rounded-md
    hover:bg-red-300
    active:bg-red-900
}

.secondaryButton {
  @apply
    select-none
    bg-gray-800
    text-white
    py-2
    px-4
    rounded-md
    hover:bg-gray-500
    active:bg-gray-900
}

.tertiaryButton {
  @apply
    select-none
    bg-gray-500
    text-white
    py-2
    px-4
    rounded-md
    hover:bg-gray-900
    active:bg-gray-500
}

a.primaryButton,
a.primaryButtonAlt,
a.secondaryButton,
a.tertiaryButton {
  @apply
    text-center
    no-underline
}

.biggerButton {
  @apply p-5
}

.smallerButton {
  @apply py-2 px-2 text-sm
}

.connectWalletButton {
  @apply
    flex
    justify-between
    items-center
    bg-gray-900
    py-2
    px-4
    text-white
    text-left
    rounded-md
    hover:bg-gray-700
}

.darkTable {
  @apply table-auto rounded-xl bg-gray-800

}

.darkTable tbody tr {
  @apply transition-all
}

.darkTable tbody tr:hover {
  @apply bg-gray-500 bg-opacity-10
}

.darkTable td {
  @apply p-4 text-sm
}

.darkTable th {
  @apply px-4 py-3 text-xs text-left uppercase font-normal text-gray-500 border-solid border-b-[1px] border-black;
  @apply bg-gray-500 bg-opacity-20;
}

.darkTable thead th:first-child {
  @apply rounded-tl-xl
}

.darkTable thead th:last-child {
  @apply rounded-tr-xl
}

.darkTable tr:nth-child(2n) td {
  @apply bg-gray-500 bg-opacity-20
}

.darkTable tr:last-child td:first-child {
  @apply rounded-bl-xl
}

.darkTable tr:last-child td:last-child {
  @apply rounded-br-xl
}